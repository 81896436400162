<script lang="ts">
	import { onMount } from 'svelte';
	import type { Feedback } from "$lib/domain/feedback.interface";

	export let review: Feedback;
	export let visible: boolean;

	$: hidden = !visible;

	onMount(() => {
			if (typeof IntersectionObserver === 'undefined') {
					return;
			}

			let observer = new IntersectionObserver(
					(entries, observer) => {
							entries.forEach((entry) => {
									if (!entry.isIntersecting) {
											return;
									}

									const htmlTarget = entry.target as HTMLImageElement;
									htmlTarget.src = htmlTarget.dataset.src || '';
									htmlTarget.classList.remove('lazyload');
									observer.unobserve(htmlTarget);
							});
					},
					{ threshold: 0, rootMargin: '20px' }
			);

			document.querySelectorAll(`.lazyload`).forEach((entry) => observer.observe(entry));
	});
</script>

<article class:hidden="{hidden}" itemscope itemtype="https://schema.org/Review">
	<div class="review-card-content flex-column">
		<div class="card-img">
			<img src="/reviews/{review.icon}.webp"
					 alt="{review.name}"
					 itemprop="image">
		</div>
		<div class="review-card-content-text">
			<p itemprop="reviewBody">
				{@html review.text}
			</p>
			<p class="review-card-title" itemprop="author" itemscope itemtype="https://schema.org/Person">
				<span itemprop="name">{#if review.name}{review.name},{/if} {review.jobTitle}</span>
			</p>
		</div>
		
		<meta itemprop="datePublished" content="{review.datePublished}">
		<div itemprop="reviewRating" itemscope itemtype="https://schema.org/Rating">
			<meta itemprop="ratingValue" content="5">
			<meta itemprop="bestRating" content="5">
			<meta itemprop="worstRating" content="1">
		</div>
		<div itemprop="itemReviewed" itemscope itemtype="https://schema.org/Product">
			<meta itemprop="name" content="{review.productName}">
			<meta itemprop="description" content="{review.productDescription}">
			<meta itemprop="image" content="{review.productImg}">
		</div>
	</div>
</article>

<style lang="scss">
	@import '$styles/vars';

	.hidden {
		display: none !important;
		opacity: 0;
	}

	article {
		padding: 36px;
		align-items: center;
		border-radius: 12px;
		background: $second-black;
		backdrop-filter: blur(12px);
		opacity: 1;

		.review-card-content {
			flex-direction: row;
			column-gap: 48px;
			color: $main-white;
			max-width: 100%;
			line-height: 20px;
			animation-name: fadeInOpacity;
			animation-iteration-count: 1;
			animation-timing-function: ease-in;
			animation-duration: 500ms;

			.card-img {
				img {
					width: 230px;
				}
			}

			p {
				font-weight: 400;
				font-size: 14px;
				margin: 0;

				&:not(:first-child) {
					margin-top: 12px;
				}
			}
		}

		.review-card-content-text{
			padding-top: 5px;
			padding-bottom: 5px;

			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.review-card-title {
				font-size: 16px;
				font-weight: 700;
			}
		}
	}

	@keyframes fadeInOpacity {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@media (max-width: $portrait-phone-size) {
		article {
			padding: 24px 12px;

			.review-card-content {

				.card-img {
					display: none;
				}

				p {
					font-size: 14px;
				}
			}
		}
	}
</style>
